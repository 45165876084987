<template>
<div :class="{'col-12 col-lg-3 col-sm-6': size == 'large', 'col-12 col-sm-6 col-lg-2' : size == 'small', 'col-12 col-sm-4 col-lg-2': !size}">
  <div class="card" :style="{'min-height': showCustomLoad ? '128px': ''}">
    <div v-if="showCustomLoad && !isLoading" style="height: 100%;align-items: center;display: flex;">
      <half-circle-spinner :size="60" color="#ddd"></half-circle-spinner>
    </div>
    <div v-else class="card-body p-3 text-center">
      <div class="text-right" :class="{'text-green': status == 'increase', 'text-red': status == 'decrease'}">

        {{percentage !== '' ? percentage : '&nbsp;'}}

        <i class="fe fe-chevron-up" v-show="status == 'increase'"></i>
        <i class="fe fe-chevron-down" v-show="status == 'decrease'"></i>
      </div>
      <div class="h1 m-0 text-size-card-info">{{value}}</div>
      <div class="text-muted mb-4 mt-2">{{title}}</div>
    </div>
  </div>
</div>
</template>

<script>
// import config from '@/config';
import {
  HalfCircleSpinner
} from 'epic-spinners'

export default {
  name: "CardInfo",
  props: ['title', 'value', 'percentage', 'status', 'size', 'showCustomLoad', 'isLoading'],
  components: { HalfCircleSpinner }
};
</script>

<style>
.text-size-card-info {
  font-size: 1.2rem !important;
}
</style>
